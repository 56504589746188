<template>
  <div class="animated">
    <div id="snackbar"></div>
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of Censor Ratings
        <div class="card-header-actions"></div>
      </b-card-header>
      <b-card-body>
        <b-button
          type="submit"
          size="sm"
          @click="
            () => {
              $router.push('/ratings/censor-ratings-form/new');
            }
          "
          variant="primary"
          class="text-right"
        >
          <i class="fa fa-plus-square-o"></i> Add
        </b-button>
        <v-client-table
          :columns="columns"
          :data="data"
          :options="options"
          :theme="theme"
          id="dataTable"
        >
          <div slot="LanguageID" slot-scope="props">
            {{ props.row.ID }}
          </div>

          <div slot="ID" slot-scope="props">
            <i class="fa fa-edit" @click="editRatings(props.row.ID)"></i>
          </div>
          <div slot="RatingImage" slot-scope="props">
            <img v-if="props.row.RatingImage" :src="props.row.RatingImage" class="img-fluid" alt width="40" height="40" />
            <!-- BaseURL +  -->
          </div>
          <div slot="Description" slot-scope="props">
            <span v-html="props.row.Description">{{
              props.row.Description
            }}</span>
          </div>

          <div slot="IsActive" slot-scope="props">
            <!-- <c-switch
              class="mx-1"
              color="primary"
              v-model="props.row.IsActive"
              variant="3d"
              label
              v-bind="labelIcon"
              @change.native="changeStatus(props.row.ID)"
            /> -->
            <span>{{props.row.IsActive?"Active":"In-Active"}}</span>
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import CensorRatingService from "@/services/CensorRatingService";

import { Switch as cSwitch } from "@coreui/vue";
import _ from "lodash";

export default {
  name: "AllMovies",
  components: {
    cSwitch,
  },
  data: function () {
    return {
      BaseURL: "",
      columns: [
        "LanguageID",
        "RatingImage",
        "Title",
        "Description",
        "IsActive",
        "ID",
      ],
      data: [],
      options: {
        headings: {
          LanguageID: "SN.",
          RatingImage: "Image",
          Title: "Rating",
          Description: "Description",
          IsActive: "Status",
          ID: "Edit",
        },
        sortable: ["Title"],
        filterable: ["Title"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  mounted() {
    this.getAllMovieRatings();
  },
  methods: {
    getAllMovieRatings() {
      this.data = [];
      let payload = { CountryID: 113 };
      CensorRatingService.getAllMovieRatings(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status && data.Rating.length > 0) {
            this.data = data.Rating;
            // this.data = data.Rating.reverse();
            this.BaseURL = data.BaseURL;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editRatings: function (RatingsID) {
      this.$router.push({
        path: "/ratings/censor-ratings-form/" + RatingsID,
      });
    },
    changeStatus: function (ID) {
      // let res = await CensorRatingService.activateOrDeactivate(ID, "Movies");
      let payload = {
        TableName: "Movies",
        ID,
      };
      let index = _.findIndex(this.data, { MovieID: ID });
      CensorRatingService.activateOrDeactivate(payload)
        .then((response) => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
          if (data.Status) {
            this.data[index].IsActive = IsActive;
          } else {
            this.data[index].IsActive = !IsActive;
          }
        })
        .catch((error) => {
          console.log("Catch on DashUsers-changeStatus Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
